// @flow
import * as React from 'react';
import type { HOC } from 'recompose';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import type { PaletteColorOptions } from '@material-ui/core/styles/createPalette';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as colorManipulator from '@material-ui/core/styles/colorManipulator';

export type Props = {
  colorPrimary?: string | PaletteColorOptions,
  children: React.Node,
};

const SubThemeProvider = ({ colorPrimary, ...props }: Props) => {
  return (
    <ThemeProvider
      {...props}
      theme={React.useCallback(
        (theme: Theme): Theme => {
          const palette = createPalette({
            primary:
              typeof colorPrimary === 'string'
                ? { main: colorPrimary }
                : colorPrimary,
            secondary: {
              main: theme.palette.common.white,
            },
          });

          return {
            ...theme,
            palette: {
              ...theme.palette,
              primary: palette.primary,
              secondary: palette.secondary,
            },
          };
        },
        [
          // IMPORTANT: stringify this as it can be an object and shallow comparison would
          // cause the theme to be recreated each time and causing issues to the classes naming
          JSON.stringify(colorPrimary),
        ],
      )}
    />
  );
};

SubThemeProvider.defaultProps = {
  colorPrimary: undefined,
};

export default SubThemeProvider;

export function withSubThemeProvider<E: {}>(
  getSubThemeProviderProps: (
    props: E,
    typeof colorManipulator,
  ) => $Diff<Props, { children: any }>,
): HOC<E, E> {
  return Component => {
    const WithSubThemeProvider = (props: E): React.Node => (
      <SubThemeProvider {...getSubThemeProviderProps(props, colorManipulator)}>
        <Component {...props} />
      </SubThemeProvider>
    );

    return WithSubThemeProvider;
  };
}
